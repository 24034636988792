








































import toggleFullscreen from 'toggle-fullscreen'

import ModelGeometryRenderer from '@/services/model-geometry-renderer'
import bus from '@/services/event-bus'

const displayConf = {
  default: {
    meshSurfaceOpacity: 1,
    meshWireframeOpacity: 0,
  },
  wireframe: {
    meshSurfaceOpacity: 0.1,
    meshWireframeOpacity: 0.5,
  },
}

export default {
  name: 'geometry-viewer',
  props: {
    geometryData: {
      type: Object,
    },
  },
  data() {
    return {
      structure: {
        compartments: [],
        membranes: [],
      },
      structureTypeLabel: {
        compartments: 'Compartments',
        membranes: 'Membranes',
      },
      displayWireframeMode: false,
    }
  },
  mounted() {
    this.renderer = new ModelGeometryRenderer(this.$refs.canvas)

    this.onLayoutChangeBound = this.onLayoutChange.bind(this)
    bus.$on('layoutChange', this.onLayoutChangeBound)

    setTimeout(() => this.initGeometry(), 10)
  },
  beforeDestroy() {
    bus.$off('layoutChange', this.onLayoutChangeBound)
    this.renderer.destroy()
  },
  methods: {
    initGeometry() {
      this.renderer.initGeometry(this.geometryData, displayConf.default)
      const structure = this.geometryData.structures.map((st, idx) => ({
        name: st.name,
        color: this.renderer.colors[idx].css(),
        visible: true,
        type: st.type,
      }))

      this.structure.compartments = structure.filter((st) => st.type === 'compartment')
      this.structure.membranes = structure.filter((st) => st.type === 'membrane')
    },
    onLayoutChange() {
      this.renderer.onResize()
    },
    onVisibilityChange(comp) {
      this.renderer.setVisible(comp.name, comp.visible)
    },
    onDisplayModeChange(wireframe) {
      this.renderer.setDisplayConf(displayConf[wireframe ? 'wireframe' : 'default'])
    },
    async toggleFullscreen() {
      await toggleFullscreen(this.$refs.container)
      // TODO: investigate more, refactor
      // workaround for Safari where size of the container
      // is not updated immediately after entering full screen mode
      setTimeout(() => this.renderer.onResize(), 500)
    },
  },
  watch: {
    geometryData() {
      this.renderer.clearGeometry()
      this.initGeometry()
    },
  },
}
