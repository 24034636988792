





































































import GeometryViewer from './geometry-viewer.vue'
import { post } from '@/services/api'

const uploadComponentFormat = ['node', 'ele', 'face', 'json']

export default {
  name: 'model-import',
  props: ['value'],
  components: {
    'geometry-viewer': GeometryViewer,
  },
  data() {
    return {
      uploadComponentFormat,
      name: '',
      description: '',
      loading: false,
      tetGenFileNameBase: null,
      error: '',
      files: {},
    }
  },
  methods: {
    beforeUpload(file) {
      this.files = { ...this.files, [file.name.split('.').pop()]: file }
      // prevent default action to upload data to remote api
      return false
    },

    removeFile(type) {
      this.files = { ...this.files, [type]: undefined }
    },
    async onOk() {
      const form = new FormData()
      form.append('name', this.name)
      form.append('annotation', this.description)
      form.append('model_id', this.$store.state.model.id)
      form.append('user_id', this.$store.state.model.user_id)
      for (const file of Object.values(this.files)) form.append('files', file as Blob)
      const geometry = (await post('geometries', form)).data
      this.$emit('input', geometry)
    },
  },
}
